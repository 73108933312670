import React, { useState } from "react";
import "./UploadForm.css";

const UploadForm = () => {
	const [companyName, setCompanyName] = useState("");
	const [folderName, setFolderName] = useState("");
	const [fileInput, setFileInput] = useState(null);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const handleSubmit = async (event) => {
		event.preventDefault();
		setError("");
		if (!fileInput) {
			setError("Please select a file to upload.");
			return;
		}

		if (fileInput[0].size > 10485760) {
			// 10MB limit
			setError("File size exceeds the 10MB limit.");
			return;
		}

		setLoading(true);
		try {
			for (const file of fileInput) {
				const response = await fetch(
					"https://hxkbvo07y7.execute-api.ap-south-1.amazonaws.com/uploadPython",
					{
						method: "POST",
						headers: {
							"Content-Type": "application/json",
						},
						body: JSON.stringify({
							fileName: file.name,
							fileType: file.type,
							company: companyName,
							folder: folderName,
						}),
					}
				);

				if (!response.ok) {
					throw new Error("Failed to get pre-signed URL");
				}

				const { uploadURL } = await response.json();
				const uploadResponse = await fetch(uploadURL, {
					method: "PUT",
					body: file,
					headers: {
						"Content-Type": file.type,
					},
				});

				if (!uploadResponse.ok) {
					throw new Error("Failed to upload file");
				}
			}

			alert("File uploaded successfully!");
			setCompanyName("");
			setFolderName("");
			setFileInput(null);
		} catch (error) {
			console.error("Error:", error);
			setError("An error occurred while uploading the file. Please try again.");
		} finally {
			setLoading(false);
		}
	};

	return (
		<div className="container">
			<h1>GBL Client File Upload Portal</h1>
			<img
				src="https://assets.worldbenchmarkingalliance.org/app/uploads/2020/10/gbl-w-full-form-black-Ankita-Nanda.png"
				alt="Logo"
				className="logo"
			/>
			<form id="uploadForm" onSubmit={handleSubmit}>
				<div className="form-group">
					<label htmlFor="companyName">Company Name:</label>
					<input
						type="text"
						id="companyName"
						value={companyName}
						onChange={(e) => setCompanyName(e.target.value)}
						placeholder="Company Name"
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="folderName">File Name:</label>
					<input
						type="text"
						id="folderName"
						value={folderName}
						onChange={(e) => setFolderName(e.target.value)}
						placeholder="File Name"
						required
					/>
				</div>
				<div className="form-group">
					<label htmlFor="fileInput">Select File:</label>
					<input
						type="file"
						id="fileInput"
						onChange={(e) => setFileInput(e.target.files)}
						required
					/>
				</div>
				{error && <p className="error">{error}</p>}
				<button type="submit" id="uploadButton" disabled={loading}>
					{loading ? "Uploading..." : "Upload"}
				</button>
			</form>
		</div>
	);
};

export default UploadForm;
